export function param2Obj(url = window.location.href) {
  const search = url.split('?')[1]
  if (!search) {
    return {}
  }
  return JSON.parse(
    '{"' +
      decodeURIComponent(search)
        .replace(/"/g, '\\"')
        .replace(/&/g, '","')
        .replace(/=/g, '":"')
        .replace(/\+/g, ' ') +
      '"}'
  )
}
/* 正常获取url参数 */
export function param(name) {
  const reg = new RegExp(`(^|&)${name}=([^&]*)(&|$)`)
  const r = window.location.search.substr(1).match(reg)
  return null !== r ? decodeURIComponent(r[2]) : ''
}

/* url中hash符号#后面的参数 */
export function getHashParam(name) {
  let r = window.location.href.split('?')[1]
  r = r ? r.split('&') : []
  const res = {}
  for (let i = 0; i < r.length; i++) {
    const el = r[i]
    res[el.split('=')[0]] = el.split('=')[1]
  }
  return res[name]
}

/* 获取url所有参数 */
export function parse (url) {
  //- window.location.search：从问号 (?) 开始的 URL
  url = url || window.location.search;
  //- 对编码后的 URI 进行解码
  url = decodeURIComponent(url);
  var result = {};
  url.replace(/([^?&]+)=([^?&]+)/g, function(s, v, k) {
      result[v] = k;
  });
  return result;
}
