export default [
  // {
  //   path: '/',
  //   redircet: '/login'
  // },
  {
    path: '/',
    name: 'index',
    component: () => import('@/pages/index')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/login')
  },
  {
    path: '/redirect',
    name: 'Redirect',
    component: () => import('@/pages/redirect')
  },
  // {
  //   path: '/profile/password',
  //   name: 'ProfilePassword',
  //   component: () => import('@/pages/profile/password')
  // },
  { path: '*', redirect: '/login', hidden: true }
]
