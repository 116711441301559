import '@/assets/style/index.scss'

import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import Element from 'element-ui'
import api from './api'
import Icon from '@/common/icon'
import { getHashParam } from './utils/index'
import Cookies from 'js-cookie'

/* 钉钉登录会重定向地址，只能将这两个数据保存在Cookie，以便下次钉钉跳转回来使用*/
if (getHashParam('code')) {
  Cookies.set('nauth-code', getHashParam('code'))
}
if (getHashParam('url')) {
  Cookies.set('nauth-redirect', decodeURIComponent(getHashParam('url')), { path: '/', domain: '.98du.com' })
}

Vue.use(Element)
Vue.use(Icon)

Vue.config.productionTip = false
Vue.prototype.$api = api

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
