<template>
  <i :class="icon" class="icon"></i>
</template>

<script>
export default {
  name: 'icon',
  props: {
    icon: String
  }
}
</script>