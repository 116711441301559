import Vue from 'vue'
// import Store from '@/store'
// import { Message } from 'element-ui'
import Router from 'vue-router'
import routes from './routes'
// import Cookies from 'js-cookie'

Vue.use(Router)

const vueRouter = new Router({
  mode: 'history',
  routes
})

// 路由设置权限
// vueRouter.beforeEach((to, from, next) => {
//   if (!Cookies.get('nauth-env') && (to.path !== '/index' || to.path !== '/')) {
//     next('/')
//   } {
//     next()
//   }
// })

export default vueRouter