import request from '@/utils/request'

const post = (url, params) => request({
  method: 'post',
  url: `/auth/${url}`,
  params
})

export function login(params) {
  return post('login', params)
}

export function logout(params) {
  return post('logout', params)
}

export function getUserInfo(params) {
  return post('user', params)
}

export function getCaptcha(params) {
  return post('captcha', params)
}

export function checkCode(params) {
  return request('auth/snsapi_login', {
    method: 'post',
    ...params
  })
}

export function loginWithPhoneNumber(params) {
  return request('/auth/passwordLogin', {
    method: 'get',
    params: params,
  })
}
